import { getParams, initGoogleAnalytics, setHtmlTitle } from '@/utility';
import types from '@/store/mutationTypes';
import { getConfigService, busService } from '@/utility';
import gamesStatic from '@nsftx/games-sdk-js/src/api/gamesStatic';
import i18n from '@/i18n';
import {assign} from 'lodash';
import rgsInit from './rgsInit';

function isiOSOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor || window.opera;

  return /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
}

const init = async (store) => {
  const params = getParams();
  const iosApp = params.application === 'mobile' && isiOSOperatingSystem();
  store.commit(types.SET_IOS_APP, iosApp);
  store.commit(types.SET_PARAMS, params);
  let isReady;
  const { pathname } = window.location;
  const isGame = pathname === '/';
  if ((!store.getters.isDesktop && isGame) || store.getters.layoutTerminal || (!store.getters.isIntegration && isGame)) {
    document.getElementsByTagName("html")[0].style.height = '100%';
    document.getElementsByTagName("body")[0].style.height = '100%';
    document.getElementById("app").style.height = '100%';
  }
  try {
    const config = await getConfigService(store);

    setHtmlTitle(config.applicationName);
    initGoogleAnalytics(config);

    if (store.getters.layoutTerminal) {
      if (store.state.gatewayConfigurationDetails?.deviceId && !config.deviceId) {
        config.deviceId = store.state.gatewayConfigurationDetails.deviceId;
      }
      if (store.state.gatewayConfigurationDetails?.productId && !config.productId) {
        config.productId = store.state.gatewayConfigurationDetails.productId;
      }
    }
    await store.commit(types.SET_CONFIG, config);

    await store.commit(types.SET_OFFER, config.state.eventsOffer);
    const translations = await gamesStatic.getTranslations(config?.locale || 'en');
    const translationsApp = (await i18n(config?.locale || 'en')).default;
    await store.dispatch('setTranslations', assign(translations, translationsApp)); // sdk action
    window.busService = await busService(config, store);
    if (store.getters.queryParametars.clientType === 'token'
      && store.getters.queryParametars.token) {
      await rgsInit(store);
    }
    if (config.deviceId && store.getters.isTerminal) {
      window.busService.addChannel('Device', config.deviceId);
    }
    const betslipConfig = {
      betLayout: 'B',
      channel: config.applicationName,
      ticketTypes: ['single', 'combo'],
      rules: config.rules,
      taxes: config.taxes,
      isPossibleWinActive: true,
      isPaymentBetAllowed: true,
      isPaymentPerBetAllowed: true,
      isPaymentPerBetStrategy: true,
      isFuturePerBetAllowed: false,
      isFuturePerTicketAllowed: false,
      isTicketCancelAllowed: false,
      isTicketRebetAllowed: false,
      isAddButtonActive: false,
      minIncrement: 0.01,
    };
    await store.dispatch('gamesBetslip/setConfig', betslipConfig);
    isReady = true;
  } catch (e) {
    isReady = false;
  }
  return isReady;
};

export default init;
