import { authenticateUser, getBalance, loginCheck } from '../api/seven';
import createUserFromRsg from './createUserFromRsg';
import EventBus from '@nsftx/games-sdk-js/src/utility/eventBus';

// eslint-disable-next-line no-unused-vars
let balanceTimeout = null;
const getUserBalance = async (data, token, store) => {
  const manualBalanceUpdate = store.getters.ui?.config?.manualBalanceUpdate || false;
  const manualBalanceTimeoutValue = store.getters.ui?.config?.manualBalanceTimeoutValue || 60000;
  if (!manualBalanceUpdate) return;
  const balance = await getBalance(data, token);
  store.dispatch('user/setUserBalance', balance);
  balanceTimeout = setTimeout(() => {
    getUserBalance(data, token, store);
  }, manualBalanceTimeoutValue);
};

const rgsInit = async (store) => {
  const data = {
    playerId: store.getters.queryParametars.playerId,
    token: store.getters.queryParametars.token,
    application: 'Web',
    locale: store.getters.queryParametars.locale,
    tenantId: store.getters.config.tenantId,
  };
  const auth = await authenticateUser(data);
  store.dispatch('setOddType', 1);
  if (auth.status !== 200) return;
  store.dispatch('setTpToken', data.token);
  const userLoginCheck = await loginCheck(data, auth.headers['access-token']);
  if (userLoginCheck.status !== 200)  return;
  store.commit('SET_RGS_INTEGRATION', true);
  const userData = createUserFromRsg({
    token: auth.headers['access-token'],
    balance: 0,
    ...userLoginCheck.data,
  });
  console.log('userData', userData);
  store.commit('SET_TIME_ZONE', userData.timezone);
  store.dispatch('setUser', {
    id: userData?.id,
    removeUser:false,
  });
  store.dispatch('user/updateUser', {
    auth: {
      token: userData.token,
      tpToken: userData.token,
    },
    profile: {
      ...userData,
      logged: true,
    },
    isLoggedIn: true,
  });
  await getUserBalance(data, auth.headers['access-token'], store);
  store.dispatch('handleFavourites', {
    method: 'get',
    type: 'get',
  });
  EventBus.$on('updateBalance', () => {
    clearTimeout(balanceTimeout);
    getUserBalance(data, store.getters.user.token, store);
  });
};

export default rgsInit;
