const setHtmlTitle = (channel) => {
  if (!channel) {
    console.warn('No channel name has been provided for setHtmlTitle')
    return
  }

  let documentTitle

  switch (channel.toLowerCase()) {
    case 'web':
      documentTitle = process.env.VUE_APP_HTML_WEB_TITLE
      break
    case 'terminal':
      documentTitle = process.env.VUE_APP_HTML_TERMINAL_TITLE
      break
    case 'mobile':
      documentTitle = process.env.VUE_APP_HTML_MOBILE_TITLE
      break
    default:
      documentTitle = process.env.VUE_APP_HTML_WEB_TITLE
      break
  }

  document.title = documentTitle
}

export default setHtmlTitle
