import { gtag } from '@nsftx/games-sdk-js/src/utility'

const initGoogleAnalytics = (config, device) => {
  if (config?.ui?.config?.gtm && config.ui.config.gtm[device]) {
    const { code, auth, preview } = config.ui.config.gtm[device]
    gtag.setup(code, auth, preview)
    return
  }
  gtag.setup()
}

export default initGoogleAnalytics
